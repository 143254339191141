import React from "react";
import { graphql } from "gatsby";

import FixtureComponent from "../../../components/Fixture";
import { SeoComponent } from "../../../components/SeoComponent";
import { useAllMatches } from "../../../hooks/useAllMatches";

function RoundFixture(props) {
  const round = props.data.roundsJson;
  const { areAllMatchesPlayed, allMatches } = useAllMatches();
  const matches = allMatches.filter((m) => Number(m.round) === round.value);
  return (
    <FixtureComponent
      matches={matches}
      round={round.value}
      areAllMatchesPlayed={areAllMatchesPlayed}
      title={`${round.value}. Hafta Süper Lig Fikstürü`}
    />
  );
}

export default RoundFixture;

export const query = graphql`
  query ($value: Int) {
    roundsJson(value: { eq: $value }) {
      id
      value
    }
  }
`;

export const Head = ({ data }) => {
  const { value } = data.roundsJson;
  return (
    <SeoComponent
      title={`${value}. Hafta Süper Lig Fikstürü`}
      description={`Türkiye Futbol Süper Ligi 2024-25 Sezonu ${value}. Hafta fikstürü ve maç sonuçları`}
      keywords={`${value}. Hafta, maç sonuçları, mac sonuclari, fikstür, fikstur, Süperlig, Superlig, ensuperlig, ensüperlig`}
    />
  );
};
